/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.1
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of account for the payment
 */
export type AccountType =
  | 'BANK_ACCOUNT_SAVINGS'
  | 'BANK_ACCOUNT_CHEQUING'
  | 'BANK_ACCOUNT_LINE_OF_CREDIT'
  | 'VISA_CREDIT'
  | 'VISA_DEBIT'
  | 'MASTERCARD_CREDIT'
  | 'MASTERCARD_DEBIT'
  | 'AMEX_CREDIT';

export const AccountType = {
  BankAccountSavings: 'BANK_ACCOUNT_SAVINGS' as AccountType,
  BankAccountChequing: 'BANK_ACCOUNT_CHEQUING' as AccountType,
  BankAccountLineOfCredit: 'BANK_ACCOUNT_LINE_OF_CREDIT' as AccountType,
  VisaCredit: 'VISA_CREDIT' as AccountType,
  VisaDebit: 'VISA_DEBIT' as AccountType,
  MastercardCredit: 'MASTERCARD_CREDIT' as AccountType,
  MastercardDebit: 'MASTERCARD_DEBIT' as AccountType,
  AmexCredit: 'AMEX_CREDIT' as AccountType,
};
