import { createAction, props } from '@ngrx/store';
import { FinancialInstitution, FooterConfig, HeaderConfig, Notices } from './core.interface';
import { ERROR_CODE, ERROR_TYPE, OIDC_CODE, OIDC_ERROR } from '@core/constants/error-code';
import { DisableReason } from '@api/model/disableReason';
import { UpdateDevice } from '@api/index';
import { HttpErrorResponse } from '@angular/common/http';

export const getFinancialInsitutions = createAction('[CORE] GET_FINACIAL_INSTITUTIONS');

export const getFinancialInsitutionsSuccess = createAction(
  '[CORE] GET_FINACIAL_INSTITUTIONS_SUCCESS',
  props<{ financialInstitutions: FinancialInstitution[] }>()
);

export const getFinancialInsitutionsFailed = createAction(
  '[CORE] GET_FINACIAL_INSTITUTIONS_FAILED',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setHeaderConfig = createAction(
  '[CORE] SET_HEADER_CONFIG',
  props<{ headerConfig: Partial<HeaderConfig> }>()
);

export const setFooterConfig = createAction(
  '[CORE] SET_FOOTER_CONFIG',
  props<{ footerConfig: Partial<FooterConfig> }>()
);

export const setFilteredAccountDisableReasons = createAction(
  '[CORE] SET_FI_ACCOUNT_DISABLE_REASONS',
  props<{ filteredAccountDisableReasons: Array<DisableReason> }>()
);

export const setMerchantReferrer = createAction('[CORE] SET_MERCHANT_REFERRER', props<{ merchantReferrer: string }>());
export const setError = createAction(
  '[CORE] ERROR',
  props<{ errorCode: ERROR_CODE; errorType?: ERROR_TYPE; errorBaseUrl?: string; retryUrl?: string }>()
);

export const hydrateCore = createAction(
  '[CORE] HYDRATE FROM SESSION',
  props<{ OIDCCode: OIDC_CODE; tempemail: string }>()
);

export const selectFI = createAction(
  '[CORE] SELECT_FI',
  props<{
    selectedFI: FinancialInstitution | undefined;
    redirectUrl?: string;
    stepUp?: boolean;
    noredirect?: boolean;
    userProfileRef?: string;
    consentId?: string;
    email?: string;
    rus?: boolean;
    login_hint?: string;
    nonce?: string;
    fi_user_id?: string;
  }>()
);

export const selectFIByID = createAction('[CORE] SELECT_FI_ID', props<{ selectedFIId: string }>());
export const redirectTOFILogin = createAction('[CORE] REDIRECT_FI_LOGIN', props<{ authURL: string }>());

export const setOIDCError = createAction('[CORE] SET_OIDC_ERROR', props<{ OIDCError: OIDC_ERROR }>());
export const clearOIDCError = createAction('[CORE] CLEAR_OIDC_ERROR');
export const setMaxShippingAddress = createAction(
  '[CORE] SET_MAX_SHIPPING_ADDRESS',
  props<{ maxShippingAddress: number }>()
);

export const setOIDCCode = createAction('[CORE] SET_OIDC_CODE', props<{ OIDCCode?: OIDC_CODE }>());
export const setTempEmail = createAction('[CORE] SET_TEMP_EMAIL', props<{ tempemail: string }>());

// device management
export const attachDevice = createAction('[CORE] ATTACH_DEVICE', props<{ userRef: string }>());
export const attachDeviceSuccess = createAction(
  '[CORE] ATTACH_DEVICE_SUCCESS',
  props<{ deviceId: string; userRef: string }>()
);
export const attachDeviceFailed = createAction('[CORE] ATTACH_DEVICE_FAILED', props<{ error: Error }>());
export const clearDeviceId = createAction('[CORE] CLEAR_DEVICE');
// update Device
export const updateDevice = createAction(
  '[CORE] UPDATE_DEVICE',
  props<{ userRef: string; deviceRef: string; deviceDetails: UpdateDevice }>()
);
export const updateDeviceSuccess = createAction(
  '[CORE] UPDATE_DEVICE_SUCCESS',
  props<{ deviceRef: string; userJWS: string }>()
);
export const updateDeviceFailed = createAction('[CORE] UPDATE_DEVICE_FAILED', props<{ error: Error }>());
export const showNotice = createAction(
  '[CORE] SHOW_SNACKBAR',
  props<{ notice: Notices | undefined; error?: boolean }>()
);
