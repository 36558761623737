import { AccountType } from '@api/model/accountType';

export const ACCOUNT_TYPE_LANG_KEY_MAP = {
  [AccountType.BankAccountChequing]: `COMMON.ACCOUNT_TYPE.${AccountType.BankAccountChequing}`,
  [AccountType.BankAccountSavings]: `COMMON.ACCOUNT_TYPE.${AccountType.BankAccountSavings}`,
  [AccountType.BankAccountLineOfCredit]: `COMMON.ACCOUNT_TYPE.${AccountType.BankAccountLineOfCredit}`,
  [AccountType.VisaCredit]: `COMMON.ACCOUNT_TYPE.${AccountType.VisaCredit}`,
  [AccountType.VisaDebit]: `COMMON.ACCOUNT_TYPE.${AccountType.VisaDebit}`,
  [AccountType.MastercardCredit]: `COMMON.ACCOUNT_TYPE.${AccountType.MastercardCredit}`,
  [AccountType.MastercardDebit]: `COMMON.ACCOUNT_TYPE.${AccountType.MastercardDebit}`,
  [AccountType.AmexCredit]: `COMMON.ACCOUNT_TYPE.${AccountType.AmexCredit}`,
};

export const ACCOUNT_TYPES_MAP = new Map<AccountType, Array<string>>([
  [AccountType.BankAccountChequing, ['Chequing']],
  [AccountType.BankAccountSavings, ['Savings']],
  [AccountType.BankAccountLineOfCredit, ['Line of Credit Account']],
  [AccountType.VisaCredit, ['Visa / Credit']],
  [AccountType.VisaDebit, ['Visa / Debit']],
  [AccountType.MastercardCredit, ['MasterCard / Credit']],
  [AccountType.MastercardDebit, ['MasterCard / Debit']],
  [AccountType.AmexCredit, ['Amex / Credit']],
]);
