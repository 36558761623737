import { Injectable } from '@angular/core';
import { AccountType } from '@api/model/accountType';
import { ConsentStatus } from '@api/model/consentStatus';
import { ACCOUNT_TYPE_LANG_KEY_MAP } from '@core/constants/pbb-account-types';
import { PHONE_NUMBER_BE_MASK_MAP } from '@core/constants/pbb-phone-number';
import { TranslateService } from '@ngx-translate/core';
import { NgxMaskService } from 'ngx-mask';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  phoneNumberMap: Map<number, string> = PHONE_NUMBER_BE_MASK_MAP;

  constructor(private readonly maskService: NgxMaskService, private readonly translateService: TranslateService) {}

  static GetUrlParam(paramKey: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramKey);
  }

  maskPhoneNumber(phoneNumber: string) {
    // appending 1 by default if 10 digits are entered.
    if (phoneNumber.length === 10) {
      phoneNumber = `1${phoneNumber}`;
    }

    const phoneMaskKey = phoneNumber.length;
    let mask: string;
    if (phoneMaskKey > 13) {
      mask = '+000-000-000-0000-0000000000000'; // phone number more than 10 digits
    } else {
      mask = this.phoneNumberMap.get(phoneMaskKey) as any as string;
    }
    return this.maskService.applyMask(phoneNumber, mask);
  }

  isAcceptableGrantStatus(status: ConsentStatus): boolean {
    const acceptableGrantStatus = [
      ConsentStatus.Granted,
      ConsentStatus.Approved,
      ConsentStatus.Tokenized,
      ConsentStatus.Stepup,
    ];
    return acceptableGrantStatus.some((acceptStatus) => acceptStatus === status);
  }

  getPaymentTypeTranslatedValues(paymentMethods: Array<AccountType> = []): Array<string> {
    return paymentMethods.reduce(
      (pre: Array<string>, cur: AccountType) => [
        ...pre,
        ...[this.translateService.instant(ACCOUNT_TYPE_LANG_KEY_MAP[cur] || '')],
      ],
      []
    );
  }
}
